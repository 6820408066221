import errorIcon from "@/assets/ErrorIcon.png";
import { Button } from "@/components/ui/button.tsx";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";

export const PaymentFailed = () => {
	const navigate = useCustomNavigateWithSubID({});
	const handleRetry = () => {
		//select the payment method then retry
		navigate("PaymentMethod");
	};
	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-10 ">
			<div className="flex items-center justify-center gap-6 text-red-500">
				<img src={errorIcon} alt="error" className="w-28" />
				<h1 className="text-[100px] font-bold">OOPS</h1>
			</div>
			<p className=" text-center text-2xl font-bold">
				Votre paiement a échoué, veuillez réessayer.
			</p>
			<Button onClick={handleRetry}>Réessayer</Button>
		</div>
	);
};
