import { z } from "zod";

const SignatureRequestSchema = z.object({
	opportuniteRef: z.string({
		required_error: "Veuillez saisir la réference de l'opportunité.",
	}),
	affaireRef: z.string({
		required_error: "Veuillez saisir la réference de l'affaire.",
	}),
});
export type SignatureRequestDto = z.infer<typeof SignatureRequestSchema>;

export const SignatureResponseSchema = z.object({
	eSignRef: z.string(),
	sign_url: z.string(),
});
export type SignatureResponseDto = z.infer<typeof SignatureResponseSchema>;

export const SignatureSchemaDto = z.object({
	subscriptionId: z.string(),
});
export type SignatureDto = z.infer<typeof SignatureSchemaDto>;

export const SignatureStatusResponseSchema = z
	.object({
		is_refused: z.boolean(),
		is_signed: z.boolean(),
	})
	.transform((data): SignatureStatusResponseDto => {
		return {
			isSignatureRefused: data.is_refused,
			isSignatureSigned: data.is_signed,
		};
	});

export const SignatureStatusSchema = z.object({
	isSignatureRefused: z.boolean(),
	isSignatureSigned: z.boolean(),
});

export type SignatureStatusResponseDto = z.infer<typeof SignatureStatusSchema>;
