import type { Route } from "@/routes/private.tsx";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { routesDefinition } from "@/routes/private.tsx";
import { useNavigate } from "react-router-dom";

type StateType = {
	[key: string]: string | number | boolean;
};

interface UseCustomNavigateWithSubIDProps {
	queryParam?: string;
}

export const useCustomNavigateWithSubID = ({
	queryParam,
}: UseCustomNavigateWithSubIDProps) => {
	const navigate = useNavigate();
	const subscriptionId = useSubscriptionIdFromQueryParam();

	return (route: keyof typeof Route, state?: StateType) => {
		let path: string;
		if (queryParam) {
			path = `${routesDefinition[route].path}?subscription_id=${subscriptionId}&${queryParam}`;
		} else {
			path = `${routesDefinition[route].path}?subscription_id=${subscriptionId}`;
		}
		navigate(path, {
			state: { ...state, isSafe: true },
		});
		window.scroll(0, 0);
	};
};
