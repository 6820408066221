import type { ComponentProps } from "react";
import { cn } from "@/utils";

interface PaymentSummaryRootProps extends ComponentProps<"div"> {}

export const PaymentSummaryRoot = ({
	children,
	className,
	...props
}: PaymentSummaryRootProps) => {
	return (
		<div
			className={cn(
				"flex flex-col items-center justify-center bg-[#e6e4dd]",
				className,
			)}
			{...props}
		>
			<div className="flex w-full  flex-col gap-8 p-8 lg:px-32">
				<div className="text-2xl font-bold text-primary ">
					Résumé de votre offre
				</div>
				{children}
			</div>
		</div>
	);
};

interface PaymentSummaryContentProps extends ComponentProps<"div"> {}

export const PaymentSummaryContent = ({
	children,
	className,
}: PaymentSummaryContentProps) => {
	return (
		<div className={cn("flex w-full flex-col gap-10 ", className)}>
			{children}
		</div>
	);
};

interface PaymentSummaryFooterProps extends ComponentProps<"div"> {}

export const PaymentSummaryFooter = ({
	children,
	className,
}: PaymentSummaryFooterProps) => {
	return (
		<div
			className={cn(
				"flex w-full flex-col items-center justify-center",
				className,
			)}
		>
			{children}
		</div>
	);
};
export const PaymentSummary = {
	Root: PaymentSummaryRoot,
	Content: PaymentSummaryContent,
	Footer: PaymentSummaryFooter,
};
