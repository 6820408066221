import { z } from "zod";

// Define the schema using Zod
export const FilloutResponseSchema = z.object({
	id: z.string().optional(),
	activityType: z.string().nullable().optional(),
	turnover: z.number(),
	coverageLimit1: z.string().nullable().optional(),
	coverageLimit2: z.string().nullable().optional(),
	coverageLimit3: z.string().nullable().optional(),
	coverageLimit4: z.string().nullable().optional(),
	deductible: z.string(),
	desiredEffectiveDate: z.string(),
	isCyberFraude: z.boolean().default(false),
	isGlobal: z.boolean().default(false),
	globalTurnover: z.number().nullable().optional(),
	hasConfirmedDeclarations1: z.boolean().default(false),
	hasConfirmedDeclarations2: z.boolean().default(false),
	companyName: z.string(),
	siren: z.number(),
	address: z.string(),
	city: z.string(),
	state: z.string().nullable().optional(),
	postalCode: z.string(),
	country: z.string(),
	webSiteUrl: z.string().nullable().optional(),
	email: z.string().email(),
	phoneNumber: z.string(),
	lastName: z.string(),
	firstName: z.string(),
	hasConfirmedDeclarations3: z.boolean().default(false),
	hasConfirmedDeclarations4: z.boolean().default(false),
	partnershipId: z.string().nullable().optional(),
	civility: z.string(),
});

export type FilloutResponseDto = z.infer<typeof FilloutResponseSchema>;
