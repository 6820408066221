import { useEffect } from "react";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { trpc } from "@/utils";

interface RedirectBasedOnPaymentStatusProps {
	refetchInterval?: number;
}

export const useRedirectBasedOnPaymentStatus = ({
	refetchInterval,
}: RedirectBasedOnPaymentStatusProps) => {
	const navigate = useCustomNavigateWithSubID({});
	const subscriptionId = useSubscriptionIdFromQueryParam();

	const checkPaymentQuery = trpc.payment.getStatus.useQuery(
		{
			subscriptionId,
		},
		{
			refetchInterval,
		},
	);

	useEffect(() => {
		if (checkPaymentQuery.isSuccess) {
			if (checkPaymentQuery.data.isPaid) {
				navigate("Signature");
			}
		}
	}, [checkPaymentQuery.data]);
};
