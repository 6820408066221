import { z } from "zod";

const quoteRequestSchema = z.object({
	opportuniteRef: z.string({
		required_error: "Veuillez saisir la réference de l'opportunité.",
	}),
	tarifRef: z.string({
		required_error: "Veuillez saisir la réference de la tarification.",
	}),
	dateEffet: z.string({
		required_error: "Veuillez saisir la date d'effet.",
	}),
});
export type QuoteRequestDto = z.infer<typeof quoteRequestSchema>;
