import successIcon from "@/assets/successIcon.png";

export const EmailSuccessPage = () => {
	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-10 ">
			<img src={successIcon} alt="success" />
			<h1 className=" text-center text-3xl font-bold text-primary">
				Un e-mail est envoyé à votre assuré
			</h1>
			<p className=" text-center text-xl font-light">
				Pour finaliser sa souscription d&apos;assurance CyberClear, il devra
				payer et signer son contrat.
			</p>
		</div>
	);
};
