import type { ReactNode } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { cn } from "@/utils";

interface TextInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	className?: string;
	type?: HTMLInputElement["type"];
	helperText?: string;
	endAdornment?: ReactNode;
}

export function TextInput<T extends FieldValues>({
	label,
	className,
	type = "text",
	name,
	control,
	helperText,
	endAdornment,
	disabled,
	...props
}: TextInputProps<T>) {
	return (
		<FormField
			{...props}
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className={cn("w-full", className)}>
					<FormLabel className="text-xl font-normal">{label}</FormLabel>
					<FormControl>
						<Input
							type={type}
							disabled={disabled}
							{...field}
							className="border-none bg-white p-7 text-lg"
							endAdornment={endAdornment}
						/>
					</FormControl>
					<FormMessage className="text-xs text-gray-500">
						{helperText}
					</FormMessage>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
