import Layout from "@/layouts/Layout.tsx";
import { PageWithSubIdQueryParam } from "@/lib/PageWithSubIdQueryParam.tsx";
import { PrivateRoute } from "@/lib/PrivateRoute.tsx";
import { EmailSuccessPage } from "@/pages/EmailSuccessPage.tsx";
import FilloutCyberFormPage from "@/pages/FilloutCyberFormPage.tsx";
import { PaymentFailed } from "@/pages/payment/PaymentFailed.tsx";
import { PaymentWithCardPage } from "@/pages/payment/PaymentWithCardPage.tsx";
import { PaymentWithSEPAPage } from "@/pages/payment/PaymentWithSEPAPage.tsx";
import { SubsriptionSuccess } from "@/pages/payment/SubsriptionSuccess.tsx";
import { PaymentSelect } from "@/pages/paymentSelect/PaymentSelect.tsx";
import { PaymentSelectErrorPage } from "@/pages/paymentSelect/PaymentSelectErrorPage.tsx";
import { SignatureFailed } from "@/pages/signature/SignatureFailed.tsx";
import { SignaturePage } from "@/pages/signature/SignaturePage.tsx";
import { TarificationErrorPage } from "@/pages/tarification/TarificationErrorPage.tsx";
import { TarificationPage } from "@/pages/tarification/TarificationPage.tsx";

export enum Route {
	Tarification = "Tarification",
	TarificationSuccess = "TarificationSuccess",
	TarificationFailed = "TarificationFailed",
	PaymentMethod = "PaymentMethod",
	PaymentMethodError = "PaymentMethodError",
	PaymentSEPA = "PaymentSEPA",
	PaymentCB = "PaymentCB",
	PaymentSuccess = "PaymentSuccess",
	PaymentFailed = "PaymentFailed",
	Signature = "Signature",
	SignatureFailed = "SignatureFailed",
	SubscriptionSuccess = "SubscriptionSuccess",
}

export const routesDefinition = {
	[Route.Tarification]: {
		path: "/tarification",
		element: (
			<PrivateRoute>
				<TarificationPage />
			</PrivateRoute>
		),
	},
	[Route.TarificationSuccess]: {
		path: "/tarification/succes",
		element: <EmailSuccessPage />,
	},
	[Route.TarificationFailed]: {
		path: "/tarification/echec",
		element: <TarificationErrorPage />,
	},
	[Route.PaymentMethod]: {
		path: "/paiement",
		element: <PaymentSelect />,
	},
	[Route.PaymentMethodError]: {
		path: "/paiement/erreur",
		element: <PaymentSelectErrorPage />,
	},
	[Route.PaymentSEPA]: {
		path: "/paiement/SEPA",
		element: <PaymentWithSEPAPage />,
	},
	[Route.PaymentCB]: {
		path: "/paiement/CB",
		element: <PaymentWithCardPage />,
	},
	[Route.PaymentSuccess]: {
		path: "/paiement/succes",
		element: <SubsriptionSuccess />,
	},
	[Route.PaymentFailed]: {
		path: "/paiement/echec",
		element: <PaymentFailed />,
	},
	[Route.Signature]: {
		path: "/signature",
		element: <SignaturePage />,
	},
	[Route.SignatureFailed]: {
		path: "/signature/echec",
		element: <SignatureFailed />,
	},
	[Route.SubscriptionSuccess]: {
		path: "/succes",
		element: <SubsriptionSuccess />,
	},
};
export const privateRoutes = [
	{
		path: "/",
		element: (
			<PrivateRoute>
				<FilloutCyberFormPage />
			</PrivateRoute>
		),
	},

	{
		element: (
			<PageWithSubIdQueryParam>
				<Layout />
			</PageWithSubIdQueryParam>
		),
		path: "/",
		children: Object.values(routesDefinition),
	},
];
