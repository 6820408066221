import { toast } from "@/components/ui/use-toast.ts";
import { trpc } from "@/utils";

export const useSendSubscriptionLinkToProspect = () => {
	return trpc.mailing.sendEmailAfterSubscription.useMutation({
		onError: () => {
			toast({
				title: "Erreur",
				description:
					"Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer.",
				variant: "destructive",
			});
		},
	});
};
