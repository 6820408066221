import { z } from "zod";

const addressSchema = z.object({
	address: z.string().optional(),
	postalCode: z.string().optional(),
	streetNumber: z.string().optional(),
	streetName: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
});

const companySchema = z.object({
	siret: z.string().optional(),
	siren: z.string().optional(),
	nom_entreprise: z.string().optional(),
	RS: z.string().optional(),
	address: addressSchema.optional(),
});

const clientSchema = z.object({
	title: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	email: z.string().optional(),
	mobile: z.string().optional(),
	company: companySchema.optional(),
});

export const PricingInputSchema = z.object({
	secteur: z.number().optional(),
	chiffre_affaire: z.number().optional(),
	chiffre_affaire_usa_can: z.number().optional(),
	opt_monde_entier: z.boolean().optional(),
	opt_cyber_base: z.boolean().default(false),
	franchise: z.number().optional(),
	limite_garantie: z.number(),
	client: clientSchema.optional(),
});

export type PricingInputDto = z.infer<typeof PricingInputSchema>;

export const PricingResponseSchema = z.object({
	garanties_optionnelles: z.object({
		CYBER: z.object({
			base: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
				limite_garantie: z.number(),
			}),
		}),
		Monde_entier: z
			.object({
				monde_entier_ttc: z.number(),
				monde_entier_ttc_Mensuelle: z.number(),
			})
			.optional(),
	}),
	references: z.object({
		franchise_list: z.array(z.number()),
		limites_garanties: z.array(z.number()),
		opportunteRef: z.string(),
		tarifRef: z.string(),
	}),
	ventilation: z.object({
		Total: z.object({
			total_ttc: z.number(),
			total_ttc_Mensuel: z.number(),
		}),
		categorie: z.string(),
		franchise: z.number(),
	}),
});

export type PricingResponseDto = z.infer<typeof PricingResponseSchema>;

export const PricingSimulateInputSchema = z.object({
	chiffre_affaire: z
		.number({
			required_error: "Veuillez saisir le chiffre d'affaires.",
		})
		.min(1, "Le montant de chiffre d'affaires minimum est de 1€"),
	limite_garantie: z.number({
		required_error: "Veuillez saisir la limite garantie.",
	}),
	secteur: z.number({
		required_error: "Veuillez saisir l'ID du secteur.",
	}),
	opt_monde_entier: z.boolean(),
	chiffre_affaire_usa_can: z.number(),
	opt_cyber_base: z.boolean(),
	franchise: z.number(),
});

export type PricingSimulateInputDto = z.infer<
	typeof PricingSimulateInputSchema
>;

export const PricingSimulateResponseSchema = z.object({
	garanties_optionnelles: z.object({
		CYBER: z.object({
			base: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
				limite_garantie: z.number(),
			}),
		}),
	}),
	references: z.object({
		franchise_list: z.array(z.number()),
		limites_garanties: z.array(z.number()),
		tarifRef: z.string(),
	}),
	ventilation: z.object({
		Total: z.object({
			total_ttc: z.number(),
			total_ttc_Mensuel: z.number(),
		}),
		categorie: z.string(),
		franchise: z.number(),
	}),
});

export type PricingSimulateResponseDto = z.infer<
	typeof PricingSimulateResponseSchema
>;

export const ValidateChiffreUsaCaCyberResponseSchema = z.object({
	valid: z.boolean(),
});

export type ValidateChiffreUsaCaCyberResponseDto = z.infer<
	typeof ValidateChiffreUsaCaCyberResponseSchema
>;

export const PricingDetailsSchema = z.object({
	desiredEffectiveDate: z.date(),
	annualPremiumPIT: z.number(),
	monthlyPremiumPIT: z.number(),
	turnover: z.number(),
	fileFees: z.number(),
	managementFees: z.number(),
	splittingFees: z.number(),
});

export type PricingDetailsDto = z.infer<typeof PricingDetailsSchema>;

export const FeesInputSchema = z.object({
	managementFees: z.coerce.number({
		required_error: "Veuillez entrer le montant des frais de gestion",
		invalid_type_error: "Veuillez entrer le montant des frais de gestion",
	}),

	fileFees: z.coerce.number({
		required_error: "Veuillez entrer le montant des frais de dossier",
		invalid_type_error: "Veuillez entrer le montant des frais de dossier",
	}),
	splittingFees: z.coerce
		.number({
			required_error: "Veuillez entrer le montant des frais de fractionnement",
			invalid_type_error:
				"Veuillez entrer le montant des frais de fractionnement",
		})
		.optional(),
});

export type FeesInputDto = z.infer<typeof FeesInputSchema>;
