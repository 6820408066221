import type { FormState } from "@/stores/useStore.ts";
import type { ReactNode } from "react";
import { useStore } from "@/stores/useStore.ts";
import { cn } from "@/utils";

interface PaymentMethodCardProps {
	label: string;
	icon: ReactNode;
	id: FormState["paymentMethod"];
	isDisabled?: boolean;
}

export const PaymentMethodCard = ({
	label,
	icon,
	id,
	isDisabled = false,
}: PaymentMethodCardProps) => {
	const { setPaymentMethod, paymentMethod } = useStore((state) => state);

	const onSelect = () => {
		setPaymentMethod(id);
	};
	return (
		<div
			className={cn(
				"flex flex-1 cursor-pointer flex-col items-center justify-center rounded-xl border-2 bg-white px-6 py-3",
				!isDisabled && paymentMethod === id
					? "border-primary"
					: "border-transparent",
				isDisabled && "cursor-not-allowed opacity-50",
			)}
			onClick={!isDisabled ? onSelect : undefined}
		>
			<div className="flex items-center gap-2">{icon}</div>
			<p className="text-nowrap text-2xl text-tertiary ">{label}</p>
		</div>
	);
};
