import type { CheckedState } from "@radix-ui/react-checkbox";
import { Checkbox } from "@/components/ui/checkbox";

interface CheckboxWithLabelProps {
	id: string;
	label: string;
	onChange: (checked: CheckedState) => void;
}

export function CheckboxWithLabel({
	id,
	label,
	onChange,
}: CheckboxWithLabelProps) {
	return (
		<div className="flex items-center space-x-2">
			<Checkbox id={id} onCheckedChange={onChange} />
			<label
				htmlFor={id}
				className="cursor-pointer text-lg font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
			>
				{label}
			</label>
		</div>
	);
}
