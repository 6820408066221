import { useEffect } from "react";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { trpc } from "@/utils";

interface RedirectBasedOnPaymentStatusProps {
	refetchInterval?: number;
	redirectOnFailure?: boolean;
}

export const useRedirectBasedOnSignatureStatus = ({
	refetchInterval,
	redirectOnFailure = false,
}: RedirectBasedOnPaymentStatusProps) => {
	const navigate = useCustomNavigateWithSubID({});
	const subscriptionId = useSubscriptionIdFromQueryParam();

	const checkSignatureQuery = trpc.signature.status.useQuery(
		{
			subscriptionId,
		},
		{
			refetchInterval: refetchInterval,
		},
	);
	useEffect(() => {
		if (checkSignatureQuery.isSuccess) {
			if (redirectOnFailure && checkSignatureQuery.data.isSignatureRefused) {
				navigate("SignatureFailed");
			}
			if (checkSignatureQuery.data.isSignatureSigned) {
				navigate("SubscriptionSuccess");
			}
		}
	}, [checkSignatureQuery.data]);
};
