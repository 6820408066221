import errorIcon from "@/assets/ErrorIcon.png";
import { Button } from "@/components/ui/button.tsx";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";

export const PaymentSelectErrorPage = () => {
	const customNavigate = useCustomNavigateWithSubID({});

	const handleRetry = () => {
		customNavigate("PaymentMethod");
	};

	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-10 ">
			<div className="flex items-center justify-center gap-6 text-red-500">
				<img src={errorIcon} alt="error" className="w-28" />
				<h1 className="text-[100px] font-bold">OOPS</h1>
			</div>
			<div className=" text-center text-2xl font-bold">
				<p>Une erreur s&apos;est produite. Veuillez réessayer.</p>
				<p>Si le problème persiste, veuillez contacter votre courtier.</p>
			</div>

			<Button onClick={handleRetry}>Réessayer</Button>
		</div>
	);
};
