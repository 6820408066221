import { Skeleton } from "@/components/ui/skeleton.tsx";
import { cn, formatNumber } from "@/utils";

interface ResumeItemDisplayProps {
	title: string;
	value: Date | number | undefined;
	type?: "currency" | "date";
	className?: string;
	isLoading?: boolean;
}

const ItemSkeleton = () => <Skeleton className="h-[1.25rem] w-24" />;

export const ResumeItemDisplay = ({
	title,
	value,
	type = "currency",
	className,
	isLoading = false,
}: ResumeItemDisplayProps) => {
	return (
		<li
			className={cn(
				"flex w-full items-center justify-between border-b border-b-gray-400 py-6 text-pale-black",
				className,
			)}
		>
			<p className=" text-xl font-bold">{title}</p>
			{isLoading ? (
				<ItemSkeleton />
			) : type === "currency" ? (
				<p className="text-xl font-medium  ">
					{formatNumber((value as number | undefined) || 0)} €
				</p>
			) : (
				<p className="text-xl font-medium  ">
					{new Date(value as Date).toLocaleDateString()}
				</p>
			)}
		</li>
	);
};
