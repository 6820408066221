import { useEffect } from "react";
import { useSubscriptionInfoById } from "@/api/useSubscription.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";
import { useRedirectBasedOnPaymentStatus } from "@/hooks/useRedirectBasedOnPaymentStatus.ts";
import { useRedirectBasedOnSignatureStatus } from "@/hooks/useRedirectBasedOnSignatureStatus.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { PaymentSelectLeftSection } from "@/pages/paymentSelect/PaymentSelectLeftSection.tsx";
import { PaymentSelectRightSection } from "@/pages/paymentSelect/PaymentSelectRightSection.tsx";

export const PaymentSelect = () => {
	useRedirectBasedOnSignatureStatus({});
	useRedirectBasedOnPaymentStatus({});
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const subscriptionDataQuery = useSubscriptionInfoById(subscriptionId);
	const navigate = useCustomNavigateWithSubID({});
	useEffect(() => {
		if (subscriptionDataQuery.isError) {
			toast({
				title: "Erreur",
				description:
					"Impossible de récupérer les informations de la souscription.",
				variant: "destructive",
			});
			navigate("PaymentMethodError");
		}
	}, [subscriptionDataQuery.isError]);
	return (
		<div className="flex h-full w-full flex-col gap-8 lg:flex-row lg:gap-0">
			<PaymentSelectLeftSection className="h-full lg:absolute lg:left-0 lg:top-0 lg:w-1/2" />
			<PaymentSelectRightSection className="h-full lg:absolute lg:bottom-0 lg:right-0 lg:top-0 lg:w-1/2" />
		</div>
	);
};
