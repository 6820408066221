import { addDays, isBefore } from "date-fns";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type { PeriodicityDto } from "@repos/cyber-dtos";

const initialState: FormState = {
	formGuid: null,
	paymentMethod: null,
	periodicity: undefined,
	managementFee: undefined,
	fileFee: undefined,
	createdAt: new Date(),
};

export const useStore = create<FormState & FormActions>()(
	persist(
		devtools((set) => ({
			...initialState,
			setManagementFee: (managementFee) =>
				set((state) => ({ ...state, managementFee })),
			setFileFee: (fileFee) => set((state) => ({ ...state, fileFee })),
			setFormGuid: (formGuid) => set((state) => ({ ...state, formGuid })),
			setPaymentFrequency: (periodicity) =>
				set((state) => ({ ...state, periodicity })),
			setPaymentMethod: (paymentMethod) =>
				set((state) => ({ ...state, paymentMethod })),
			reset: () => set(initialState),
		})),
		{
			name: "form-store",
			onRehydrateStorage: (state) => {
				//if createdAt is older than 1 day, we reset the stores
				if (isBefore(state.createdAt, addDays(new Date(), -1))) {
					localStorage.clear();
				}
			},
		},
	),
);

export type FormState = {
	formGuid: string | null;
	 
	periodicity: PeriodicityDto | undefined;
	paymentMethod: "SEPA" | "CB" | null;
	managementFee: number | undefined;
	fileFee: number | undefined;
	createdAt: Date;
};

type FormActions = {
	setFormGuid: (formGuid: string) => void;
	setPaymentFrequency: (periodicity: FormState["periodicity"]) => void;
	setPaymentMethod: (paymentMethod: FormState["paymentMethod"]) => void;
	setManagementFee: (managementFee: number) => void;
	setFileFee: (fileFee: number) => void;
	reset: () => void;
};
