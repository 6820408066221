import { CustomSpinner } from "@/components/CustomSpinner.tsx";
import { useRedirectBasedOnSignatureStatus } from "@/hooks/useRedirectBasedOnSignatureStatus.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { trpc } from "@/utils";
import Iframe from "react-iframe";

export const SignaturePage = () => {
	useRedirectBasedOnSignatureStatus({
		refetchInterval: 1000,
		redirectOnFailure: true,
	});

	const subscriptionId = useSubscriptionIdFromQueryParam();
	const signatureUrlQuery = trpc.signature.signature.useQuery({
		subscriptionId,
	});

	if (!signatureUrlQuery.data) {
		return <CustomSpinner />;
	}

	return (
		<Iframe
			id="signature"
			className="mt-16 h-full w-full"
			url={signatureUrlQuery.data.sign_url}
			loading="eager"
		/>
	);
};
