import {
	ActivityType,
	CoverageLimitType1,
	CoverageLimitType2,
	CoverageLimitType3,
	CoverageLimitType4,
	DeductibleType,
	SubscriberCivility,
} from "@prisma/client";
import { z } from "zod";

export const fieldsMappingSchema = z.object({
	activityType: z.string().nullable().optional(),
	turnover: z.string().nullable().optional(),
	coverageLimit1: z.string().nullable().optional(),
	coverageLimit2: z.string().nullable().optional(),
	coverageLimit3: z.string().nullable().optional(),
	coverageLimit4: z.string().nullable().optional(),
	deductible: z.string().nullable().optional(),
	desiredEffectiveDate: z.string().nullable().optional(),
	isCyberFraude: z.string().nullable().optional(),
	isGlobal: z.string().nullable().optional(),
	globalTurnover: z.string().nullable().optional(),
	companyName: z.string().nullable().optional(),
	siren: z.string().nullable().optional(),
	address: z.string().nullable().optional(),
	city: z.string().nullable().optional(),
	state: z.string().nullable().optional(),
	postalCode: z.string().nullable().optional(),
	country: z.string().nullable().optional(),
	webSiteUrl: z.string().nullable().optional(),
	email: z.string().nullable().optional(),
	phoneNumber: z.string().nullable().optional(),
	lastName: z.string().nullable().optional(),
	firstName: z.string().nullable().optional(),
	civility: z.string().nullable().optional(),
});

export type MappingDto = z.infer<typeof fieldsMappingSchema>;

// Mapping fields to French labels
export const fieldsMapping: MappingDto = {
	activityType: "Activité",
	turnover: "Chiffre d'affaires en €",
	coverageLimit1: "Limite de garantie en €",
	coverageLimit2: "Limite de garantie en €",
	coverageLimit3: "Limite de garantie en €",
	coverageLimit4: "Limite de garantie en €",
	deductible: "Franchise en €",
	desiredEffectiveDate: "Date d'effet souhaitée",
	isCyberFraude: "Cyber Fraude",
	isGlobal: "Garantie Monde Entier",
	globalTurnover: "Chiffre d'affaires USA / Canada en €",
	companyName: "Raison sociale",
	siren: "SIREN",
	address: "Adresse",
	city: "Ville",
	state: "État",
	postalCode: "Code postal",
	country: "Pays",
	webSiteUrl: "Site internet",
	email: "E-mail",
	phoneNumber: "Téléphone",
	lastName: "Nom",
	firstName: "Prénom",
	civility: "Civilité",
};

export const SubscriptionSchema = z.object({
	id: z.string().optional(),
	activityType: z.nativeEnum(ActivityType),
	turnover: z.number(),
	coverageLimit1: z.nativeEnum(CoverageLimitType1).nullable().optional(),
	coverageLimit2: z.nativeEnum(CoverageLimitType2).nullable().optional(),
	coverageLimit3: z.nativeEnum(CoverageLimitType3).nullable().optional(),
	coverageLimit4: z.nativeEnum(CoverageLimitType4).nullable().optional(),
	deductible: z.nativeEnum(DeductibleType),
	desiredEffectiveDate: z.date(),
	isCyberFraude: z.boolean(),
	isGlobal: z.boolean(),
	globalTurnover: z.number().nullable().optional(),
	companyName: z.string(),
	siren: z.string(),
	address: z.string().nullable().optional(),
	city: z.string().nullable().optional(),
	state: z.string().nullable().optional(),
	postalCode: z.string().nullable().optional(),
	country: z.string().nullable().optional(),
	webSiteUrl: z.string().nullable().optional(),
	email: z.string(),
	phoneNumber: z.string().nullable().optional(),
	lastName: z.string(),
	firstName: z.string(),
	hasConfirmedDeclarations: z.boolean(),
	partnershipId: z.string().nullable().optional(),
	civility: z.nativeEnum(SubscriberCivility),
});

export type SubscriptionDto = z.infer<typeof SubscriptionSchema>;

export const SubscriptionIdInputSchema = z.object({
	subscriptionId: z.string(),
});
