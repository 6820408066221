import { toast } from "@/components/ui/use-toast.ts";
import { trpc } from "@/utils";

export const useSubmitFees = () => {
	return trpc.pricing.fees.useMutation({
		onError: () => {
			toast({
				title: "Erreur lors de l'envoi des frais",
				variant: "destructive",
			});
		},
	});
};
