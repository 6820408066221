import type { z } from "zod";

import { GetBrokerGeoprodResponseSchema } from "../responses/broker.geoprod.response";

export const BrokerDtoSchema = GetBrokerGeoprodResponseSchema.transform(
	(data) => ({
		civility: data.user.civilite,
		email: data.user.mail,
		mobile: data.user.mobile,
		lastName: data.user.nom,
		organizationName: data.user.nom_organisme,
		firstName: data.user.prenom,
		secondaryPhone: data.user.tel2,
		phone: data.user.telephone,
		organizationId: data.organisme?.id ?? null,
		organizationAddress: data.organisme?.adresse ?? null,
		organizationEmail: data.organisme?.adress_mail ?? null,
		brokerApiKey: "",
	}),
);

export type BrokerDto = z.infer<typeof BrokerDtoSchema>;
