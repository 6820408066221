import { z } from "zod";

export const BrokerUserDtoSchema = z.object({
	civilite: z.string().nullable(),
	mail: z.string(),
	mobile: z.string().nullable(),
	nom: z.string().nullable(),
	nom_organisme: z.string().nullable(),
	prenom: z.string().nullable(),
	tel2: z.string().nullable(),
	telephone: z.string().nullable(),
});

export const OrganizationSchema = z.object({
	adress_mail: z.string(),
	adresse: z.string(),
	id: z.string(),
});

export const GetBrokerGeoprodResponseSchema = z.object({
	adresse: z.object({}).optional(),
	organisme: OrganizationSchema.optional(),
	user: BrokerUserDtoSchema,
	ville: z.object({}).optional(),
	brokerApiKey: z.string().optional(),
});

export type BrokerUserDto = z.infer<typeof BrokerUserDtoSchema>;
export type GetBrokerGeoprodResponse = z.infer<
	typeof GetBrokerGeoprodResponseSchema
>;
