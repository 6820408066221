import { useSubscriptionInfoById } from "@/api/useSubscription.ts";
import bankCard from "@/assets/bankCard.svg";
import euroIcon from "@/assets/euroIcon.svg";
import { PaymentFrequencyCard } from "@/components/PaymentFrequencyCard.tsx";
import { PaymentMethodCard } from "@/components/PaymentMethodCard.tsx";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { useStore } from "@/stores/useStore.ts";
import { cn } from "@/utils";

interface PaymentSelectLeftSectionProps
	extends React.HTMLAttributes<HTMLDivElement> {}

export const PaymentSelectLeftSection = ({
	className,
}: PaymentSelectLeftSectionProps) => {
	const { periodicity, setPaymentMethod } = useStore((state) => state);
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const { data, isLoading } = useSubscriptionInfoById(subscriptionId);
	const { annualPremiumPIT, monthlyPremiumPIT } = data || {};
	return (
		<div
			className={cn(
				" flex flex-col items-center justify-center gap-12",
				className,
			)}
		>
			<div className="flex w-9/12 flex-col gap-10 ">
				<div className="text-2xl font-bold text-primary ">
					Fractionnement souhaité
				</div>
				<div className="flex w-full flex-col gap-4 lg:flex-row">
					<PaymentFrequencyCard
						id="monthly"
						label="Paiement/mois"
						price={monthlyPremiumPIT}
						isLoading={isLoading}
						onSelect={() => {
							setPaymentMethod("SEPA");
						}}
					/>
					<PaymentFrequencyCard
						id="yearly"
						label="Paiement/an"
						price={annualPremiumPIT}
						isLoading={isLoading}
						onSelect={() => {
							setPaymentMethod("CB");
						}}
					/>
				</div>
			</div>
			<div className="flex w-9/12 flex-col gap-10 ">
				<div className="text-2xl font-bold text-primary ">
					Type de réglement souhaité
				</div>
				<div className=" flex w-full flex-col gap-4 lg:flex-row">
					<PaymentMethodCard
						id="SEPA"
						label="Mandat SEPA"
						icon={<img src={bankCard} alt="sepa" />}
						isDisabled={periodicity !== "monthly"}
					/>
					<PaymentMethodCard
						id="CB"
						label="Carte bancaire"
						icon={<img src={euroIcon} alt="credit card" />}
						isDisabled={periodicity !== "yearly"}
					/>
				</div>
			</div>
		</div>
	);
};
